import React from "react";

const ImagePreview = ({ imagePath, fileName }) => {
  return (
    <div>
      <img
        src={`data:image/png;base64,${imagePath}`}
        alt={`Preview of ${fileName}`}
        style={{ maxWidth: "100%", maxHeight: "500px", marginTop: "10px" }} // Adjust the size as needed
      />
    </div>
  );
};

export default ImagePreview;
