import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  generatedPreviewContent: "",
  requestStatus: "idle",
};

const projectInfoSlice = createSlice({
  name: "projectInfo",
  initialState,
  reducers: {
    streamUpdate: (state, action) => {
      state.generatedPreviewContent = action.payload;
    },
    streamComplete: (state, action) => {
      state.requestStatus = "success";
    },
    startStreaming: (state) => {
      state.requestStatus = "loading";
    },
    streamingError: (state, action) => {
      state.error = action.payload;
      state.requestStatus = "fail";
    },
  },
});

export const fetchGeneratedProjectInfo =
  (reportData) => (dispatch, getState) => {
    dispatch(startStreaming());
    dispatch({
      type: "projectInfo/fetchPreview/stream",
      requestUrl: "/reports/generate/anonymous/",
      payload: reportData,
    });
  };

export const { streamUpdate, streamComplete, startStreaming, streamingError } =
  projectInfoSlice.actions;

export default projectInfoSlice.reducer;
