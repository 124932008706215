// streamingMiddleware.js
import { streamUpdate, streamComplete, streamingError } from "./slice"; // adjust the import path as necessary

// Assume that 'projectInfo/fetchPreview/stream' action contains the reportData in its payload
export const streamingProjectInfoMiddleware =
  (store) => (next) => async (action) => {
    if (action.type !== "projectInfo/fetchPreview/stream") return next(action);

    const { dispatch } = store;
    const reportData = { ...action.payload }; // Get the payload from the action

    // Prepare the options for the fetch request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers as required
      },
      body: JSON.stringify(reportData), // Convert the reportData to a JSON string
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reports/generate/anonymous/`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // Handle the streaming response
      const readStream = async () => {
        const { done, value } = await reader.read();
        if (done) {
          dispatch(streamComplete());
          return;
        }
        const chunk = decoder.decode(value, { stream: true });

        dispatch(streamUpdate(chunk));
        readStream(); // Read the next chunk
      };

      // // Start reading the stream
      readStream();
    } catch (error) {
      console.error("Fetch error:", error.message);
      // Dispatch an error action or handle it as necessary
      dispatch(streamingError(error.message));
    }
  };
