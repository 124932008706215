import React, { useState } from "react";
import { Form, Card, Button, Row, Col, Modal } from "react-bootstrap";
import "./section.css";
import DeleteIcon from "./Images/trash-can.svg";
import Issue from "../Issue";
import ConfirmModal from "../../components/ConfirmModal";

const SECTION_VALUE_OPTIONS = {
  roof: { value: "roof", label: "Roof" },
  foundation: { value: "foundation", label: "Foundation" },
  electrical: { value: "electrical", label: "Electrical" },
};

const SectionCard = ({
  sectionDetails,
  sectionArray,
  handleDeleteSection,
  handleUpdateSection,
}) => {
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [issueArray, setIssueArray] = useState([]);
  const [issueDataToEdit, setIssueDataToEdit] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [issueIdToDelete, setIssueIdToDelete] = useState(null);

  const selectOptions = Object.values(SECTION_VALUE_OPTIONS).map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const handleAddOrEditIssue = (issueData) => {
    const issueToEdit = issueArray.find(
      (issue) => issue.issueId === issueData.issueId
    );

    if (issueToEdit) {
      const newIssueArray = issueArray.map((issue) => {
        if (issue.issueId === issueData.issueId) {
          return issueData;
        }
        return issue;
      });
      setIssueArray(newIssueArray);
      return;
    }

    setIssueArray([...issueArray, issueData]);
  };

  const handleDeleteIssue = (issueId) => {
    setShowConfirmModal(true);
    setIssueIdToDelete(issueId);
  };

  const onConfirmDeleteIssue = () => {
    const newIssueArray = issueArray
      .map((issue) => {
        if (issue.issueId === issueIdToDelete) {
          return null;
        }
        return issue;
      })
      .filter((issue) => issue !== null);

    setIssueArray(newIssueArray);
    setShowConfirmModal(false);
  };

  const handleShowIssueModal = (editIssueId = null) => {
    setShowIssueModal(true);

    setIssueDataToEdit(
      issueArray.find((issue) => issue.issueId === editIssueId)
    );
  };

  const handleHideIssueModal = () => {
    setShowIssueModal(false);
    setIssueDataToEdit(null);
  };

  const IssueComponentArray =
    issueArray && issueArray.length > 0
      ? issueArray.map((issue, index) => (
          <Issue
            key={issue.issueId}
            index={index}
            issueDataToEdit={issue}
            placedOnMainPage
            handleHideIssueModal={handleHideIssueModal}
            handleAddOrEditIssue={handleAddOrEditIssue}
            handleShowIssueModal={handleShowIssueModal}
            handleDeleteIssue={handleDeleteIssue}
          />
        ))
      : [];

  const onHandleDeleteSection = () => {
    handleDeleteSection(sectionDetails.sectionId);
  };

  const sectionNumber =
    sectionArray.findIndex(
      (section) => section.sectionId === sectionDetails.sectionId
    ) + 1;

  const onHandleSectionDetails = (e) => {
    const { name, value } = e.target;

    handleUpdateSection(sectionDetails.sectionId, name, value);
  };

  const { sectionName, sectionDescription } = sectionDetails;

  const getIssueNumber = () => {
    let index = 0;

    if (!issueDataToEdit) {
      index = issueArray.length;
    } else {
      index = issueArray.findIndex(
        (issue) => issue.issueId === issueDataToEdit.issueId
      );
    }

    return index + 1;
  };

  const getConfirmDeleteIssueMessage = () => {
    const issueIndexToDelete = issueArray.findIndex(
      (issue) => issue.issueId === issueIdToDelete
    );

    const sectionIndex = sectionArray.findIndex(
      (section) => section.sectionId === sectionDetails.sectionId
    );

    return `Are you sure you want to delete Issue ${
      issueIndexToDelete + 1
    } in Section ${sectionIndex + 1}?`;
  };

  return (
    <Card
      key={sectionDetails.sectionId}
      className="p-4 mb-4"
      style={{ backgroundColor: "#e5e5e5" }}
    >
      <Card.Title className="fw-bold d-flex justify-content-between">
        <span>{`Section ${sectionNumber}`}</span>
        <Button
          className="pt-0"
          style={{ background: "inherit", border: "none" }}
          onClick={onHandleDeleteSection}
        >
          <img width="18px" src={DeleteIcon} alt="delete section" />
        </Button>
      </Card.Title>
      <Card.Body>
        <Form className="project-info-form">
          <Form.Group className="mb-3" controlId="sectionName">
            <Form.Label>
              Name<span className="required-field">*</span>
            </Form.Label>
            <Form.Select
              aria-label="selection name select"
              value={sectionName}
              name="sectionName"
              onChange={onHandleSectionDetails}
            >
              <option>Please select</option>
              {selectOptions}
            </Form.Select>
          </Form.Group>
          {/* Description */}
          <Form.Group controlId="sectionDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Description"
              name="sectionDescription"
              value={sectionDescription}
              onChange={onHandleSectionDetails}
            />
          </Form.Group>
        </Form>
        <Row>
          <Col sm="3">
            <Button onClick={() => setShowIssueModal(true)}>+ New Issue</Button>
          </Col>
        </Row>
      </Card.Body>
      {IssueComponentArray}
      {showIssueModal ? (
        <Modal size="xl" show={showIssueModal} onHide={handleHideIssueModal}>
          <Card>
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">{`Issue ${getIssueNumber()}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Issue
                issueDataToEdit={issueDataToEdit}
                handleHideIssueModal={handleHideIssueModal}
                handleAddOrEditIssue={handleAddOrEditIssue}
                handleShowIssueModal={handleShowIssueModal}
                handleDeleteIssue={handleDeleteIssue}
              />
            </Modal.Body>
          </Card>
        </Modal>
      ) : null}
      {showConfirmModal ? (
        <ConfirmModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          onConfirm={onConfirmDeleteIssue}
          confirmMessage={getConfirmDeleteIssueMessage()}
        />
      ) : null}
    </Card>
  );
};

export default SectionCard;
