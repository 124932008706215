import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import "./projectInformation.css";

const ProjectInfoForm = ({
  handleChange,
  formData,
  previewClicked = false,
  previewContent = "",
  placedOnMainPage = false,
}) => {
  return (
    <Form className="project-info-form">
      {/* Report Name */}
      <Form.Group controlId="formReportName">
        <Form.Label>
          Report Name<span className="required-field">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter report name"
          name="reportName"
          required
          value={formData.reportName}
          onChange={handleChange}
          readOnly={placedOnMainPage}
        />
      </Form.Group>

      {/* Address 1 */}
      <Form.Group controlId="formAddress1">
        <Form.Label>
          Address 1<span className="required-field">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="1234 Main St"
          name="address1"
          required
          value={formData.address1}
          onChange={handleChange}
          readOnly={placedOnMainPage}
        />
      </Form.Group>

      {/* Address 2 */}
      <Form.Group controlId="formAddress2">
        <Form.Label>Address 2</Form.Label>
        <Form.Control
          type="text"
          placeholder="Apartment, studio, or floor"
          name="address2"
          value={formData.address2}
          onChange={handleChange}
          readOnly={placedOnMainPage}
        />
      </Form.Group>

      {/* City, State, Zip Code */}
      <Row>
        <Col>
          <Form.Group controlId="formCity">
            <Form.Label>
              City<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              name="city"
              required
              value={formData.city}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formState">
            <Form.Label>
              State<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="State"
              name="state"
              required
              value={formData.state}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formZip">
            <Form.Label>
              Zip Code<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip"
              name="zip"
              required
              value={formData.zip}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Year Built, Floor Area, No. of Stories */}
      <Row>
        <Col>
          <Form.Group controlId="formYearBuilt">
            <Form.Label>Year Built</Form.Label>
            <Form.Control
              type="number"
              placeholder="Year Built"
              name="yearBuilt"
              required
              value={formData.yearBuilt}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formFloorArea">
            <Form.Label>Floor Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="Floor Area (sq ft)"
              name="floorArea"
              required
              value={formData.floorArea}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formStories">
            <Form.Label>No. of Stories</Form.Label>
            <Form.Control
              type="number"
              placeholder="Number of Stories"
              name="stories"
              required
              value={formData.stories}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* House Type, Assessor's Parcel Number */}
      <Row>
        <Col>
          <Form.Group controlId="formHouseType">
            <Form.Label>House Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="House Type"
              name="houseType"
              required
              value={formData.houseType}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formParcelNumber">
            <Form.Label>Assessor's Parcel Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Parcel Number"
              name="parcelNumber"
              required
              value={formData.parcelNumber}
              onChange={handleChange}
              readOnly={placedOnMainPage}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Other Info */}
      <Form.Group controlId="formOtherInfo">
        <Form.Label>Other Info</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Any other information"
          name="otherInfo"
          required
          value={formData.otherInfo}
          onChange={handleChange}
          readOnly={placedOnMainPage}
        />
      </Form.Group>
      {placedOnMainPage && previewClicked ? (
        <Form.Group>
          <Form.Label>Preview</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="previewContent"
            value={previewContent}
            readOnly={placedOnMainPage}
          />
        </Form.Group>
      ) : null}
    </Form>
  );
};

export default ProjectInfoForm;
