import React, { useState, useEffect } from "react";
import { Form, Row, Button, Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import "./issue.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneratedIssue, resetPreviewContent } from "./slice";
import ImageIcon from "./Images/image.svg";
import ImagePreview from "../../components/ImagePreview.js";

const CONCERNING_LEVEL_OPTIONS = {
  observation: {
    value: 0,
    label: "Observation",
  },
  recommendation: {
    value: 1,
    label: "Recommendation",
  },
  safetyHazard: {
    value: 2,
    label: "Safety Hazard",
  },
};

const Issue = ({
  handleAddOrEditIssue,
  handleDeleteIssue,
  issueDataToEdit,
  placedOnMainPage = false,
  handleHideIssueModal,
  handleShowIssueModal,
  index,
}) => {
  const dispatch = useDispatch();

  const { generatedPreviewContent, requestStatus } = useSelector(
    (state) => state.issue
  );

  const [concerningLevel, setConcerningLevel] = useState(0);
  const [note, setNote] = useState("");
  const [previewClicked, setPreviewClicked] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [showImagePreview, setShowImagePreview] = useState(false);

  useEffect(() => {
    setPreviewContent(generatedPreviewContent);
  }, [generatedPreviewContent]);

  useEffect(() => {
    if (issueDataToEdit) {
      const {
        photo,
        fileName,
        concerningLevel,
        note,
        previewContent: savedRecommendation,
      } = issueDataToEdit;

      setSelectedPhoto(photo);
      setSelectedFileName(fileName);
      setConcerningLevel(concerningLevel);
      setNote(note);
      setPreviewContent(savedRecommendation);
    }
  }, [issueDataToEdit]);

  useEffect(() => {
    if (placedOnMainPage) {
      return;
    }

    if (requestStatus === "loading") {
      setPreviewContent("Loading...");
    }

    if (requestStatus === "fail") {
      setPreviewContent("");
    }

    if (requestStatus === "success") {
      setPreviewContent(generatedPreviewContent);
    }
  }, [placedOnMainPage, requestStatus, generatedPreviewContent]);

  const handleChangePreviewContent = (event) => {
    setPreviewContent(event.target.value);
  };

  const handlePreview = (event) => {
    event.preventDefault();
    setPreviewClicked(true);

    const reportData = {
      image: selectedPhoto,
      concern_level: concerningLevel,
      description_hint: note,
    };

    dispatch(fetchGeneratedIssue(reportData));
  };

  const handleUploadPhoto = (e) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // The result includes the Base64 prefix that needs to be handled
        // reader.result formatted in `data:image/png;base64,{base64String}`
        // we only need to pass the base64String part to api
        const base64Result = reader.result.split(",")[1];
        onHandleSelectingPhoto(base64Result, file.name);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file was selected
      console.log("No file selected or the file is not accessible.");
      onClearSelectingPhoto();
    }
  };

  const onHandleSelectingPhoto = (base64Result, fileName) => {
    setSelectedPhoto(base64Result);
    setSelectedFileName(fileName);
  };

  const onClearSelectingPhoto = () => {
    setSelectedPhoto(null);
    setSelectedFileName("");
  };

  const onHandleHideIssueModal = () => {
    const issueData = {
      photo: selectedPhoto,
      fileName: selectedFileName,
      concerningLevel,
      note,
      issueId: issueDataToEdit ? issueDataToEdit.issueId : uuidv4(),
      previewContent,
    };

    handleAddOrEditIssue(issueData);
    dispatch(resetPreviewContent());
    handleHideIssueModal();
  };

  const concerningLevelOptions = Object.values(CONCERNING_LEVEL_OPTIONS).map(
    (option) => (
      <Form.Check
        key={option.label}
        inline
        name={option.value}
        className="pe-3"
        label={option.label}
        type="radio"
        value={option.value}
        id={`concerning-level-${option.value}`}
        checked={Number(concerningLevel) === option.value}
        onChange={(e) => setConcerningLevel(Number(e.target.value))}
        disabled={placedOnMainPage}
      />
    )
  );

  const togglePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  return (
    <Card.Body>
      {placedOnMainPage ? (
        <Card.Title className="d-flex align-items-center">
          <Button
            variant="danger"
            className="me-2 issue-delete-button-background "
            onClick={() => handleDeleteIssue(issueDataToEdit.issueId)}
          >
            <span>×</span>
          </Button>
          {`Issue ${index + 1}`}
        </Card.Title>
      ) : null}
      <Form className="issue-form">
        <Form.Group className="mb-3" controlId="issuePhoto">
          <Form.Label>
            Photo<span className="required-field">*</span>
            <span className="sub-label ms-2">
              (png, jpeg, HEIC, no more than 8 MB)
            </span>
          </Form.Label>
          {selectedPhoto && selectedFileName ? (
            <div>
              <div className="ms-1 d-flex align-items-center">
                {placedOnMainPage ? null : (
                  <Button
                    variant="danger"
                    className="me-2 issue-delete-button-background"
                    onClick={onClearSelectingPhoto}
                  >
                    <span>×</span>
                  </Button>
                )}
                <img
                  src={ImageIcon}
                  width="18px"
                  alt="uploaded"
                  className="me-2"
                />
                <span>{selectedFileName}</span>
                <Button
                  variant="link"
                  className="px-0 ms-2"
                  onClick={togglePreview}
                >
                  {showImagePreview ? "▲ Hide Preview" : "▼ Show Preview"}
                </Button>
              </div>
              {showImagePreview ? (
                <ImagePreview
                  imagePath={selectedPhoto}
                  fileName={selectedFileName}
                />
              ) : null}
            </div>
          ) : (
            <Form.Control
              type="file"
              placeholder="Upload Photo"
              name="issuePhoto"
              required
              accept="image/*"
              onChange={handleUploadPhoto}
              readOnly={placedOnMainPage}
            />
          )}
        </Form.Group>
        {/* Concerning Level */}
        <Form.Group className="mb-3" controlId="concerningLevel">
          <Form.Label>
            Concerning Level<span className="required-field">*</span>
          </Form.Label>
          <div>{concerningLevelOptions}</div>
        </Form.Group>
        {/* Note */}
        <Form.Group controlId="issueNote">
          <Form.Label>Note</Form.Label>
          <Form.Control
            as="textarea"
            name="issueNote"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            readOnly={placedOnMainPage}
          />
        </Form.Group>
        {placedOnMainPage ? null : (
          <div className="d-flex mt-2" style={{ justifyContent: "flex-end" }}>
            <Button
              variant="primary"
              type="button"
              onClick={handlePreview}
              disabled={!selectedPhoto}
            >
              Generate
            </Button>
          </div>
        )}
      </Form>
      {previewClicked || issueDataToEdit || placedOnMainPage ? (
        <div className="mt-2">
          <Row>
            <Form className="project-info-form">
              <Form.Label>Preview</Form.Label>
              {requestStatus === "fail" ? (
                <div className="error">
                  There is something wrong with your request. Please try again
                  later.
                </div>
              ) : null}
              {requestStatus !== "fail" ? (
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="previewContent"
                  value={
                    placedOnMainPage
                      ? issueDataToEdit.previewContent
                      : previewContent
                  }
                  onChange={handleChangePreviewContent}
                  readOnly={placedOnMainPage}
                />
              ) : null}
            </Form>
          </Row>
        </div>
      ) : null}
      {(previewClicked || issueDataToEdit) && !placedOnMainPage ? (
        <div className="d-flex mt-2" style={{ justifyContent: "flex-end" }}>
          <Button
            variant="primary"
            type="button"
            onClick={onHandleHideIssueModal}
            disabled={requestStatus === "loading" || !previewContent}
          >
            Confirm and Save Draft
          </Button>
        </div>
      ) : null}
      {placedOnMainPage ? (
        <div className="d-flex mt-2" style={{ justifyContent: "flex-end" }}>
          <Button
            variant="primary"
            type="button"
            onClick={() => handleShowIssueModal(issueDataToEdit.issueId)}
          >
            Edit
          </Button>
        </div>
      ) : null}
    </Card.Body>
  );
};

export default Issue;
