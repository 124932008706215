import React, { useState, useEffect } from "react";
import { Row, Button, Card, Modal, Form } from "react-bootstrap";
import ProjectInfoForm from "./projectInfoForm";
import "./projectInformation.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneratedProjectInfo } from "./slice";
import Section from "../Section/index";

const ProjectInformation = () => {
  const dispatch = useDispatch();

  const { generatedPreviewContent, requestStatus } = useSelector(
    (state) => state.projectInfo
  );

  const [formData, setFormData] = useState({
    reportName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    yearBuilt: "",
    floorArea: "",
    stories: "",
    houseType: "",
    parcelNumber: "",
    otherInfo: "",
  });
  const [originalFormData, setOriginalFormData] = useState({
    reportName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    yearBuilt: "",
    floorArea: "",
    stories: "",
    houseType: "",
    parcelNumber: "",
    otherInfo: "",
  }); // keep track of the currently confirmed form data value
  const [showEditProjectInfoModal, setShowEditProjectInfoModal] =
    useState(false);
  const [previewClicked, setPreviewClicked] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [originalPreviewContent, setOriginalPreviewContent] = useState(""); // keep track of the currently confirmed preview content
  const [confirmProjectInfoAdded, setConfirmProjectInfoAdded] = useState(false);

  useEffect(() => {
    setPreviewContent(generatedPreviewContent);
  }, [generatedPreviewContent]);

  useEffect(() => {
    if (requestStatus === "loading") {
      setPreviewContent("Loading...");
    }

    if (requestStatus === "fail") {
      setPreviewContent("");
    }

    if (requestStatus === "success") {
      setPreviewContent(generatedPreviewContent);
    }
  }, [requestStatus, generatedPreviewContent]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePreviewContent = (event) => {
    setPreviewContent(event.target.value);
  };

  const handlePreview = (event) => {
    event.preventDefault();
    setPreviewClicked(true);

    const {
      reportName,
      address1,
      address2,
      city,
      state,
      zip,
      yearBuilt,
      floorArea,
      stories,
      houseType,
      parcelNumber,
      otherInfo,
    } = formData;
    const combinedAddress = `${address1.trim()},${address2.trim()}${
      address2 ? " ," : ""
    } ${city.trim()}, ${state.trim()}, ${zip.trim()}`;

    const reportData = {
      report_type: "structural",
      name: reportName,
      address: combinedAddress,
      year_build: yearBuilt || null,
      floor_area: floorArea || null,
      stories: stories || null,
      house_type: houseType,
      apn: parcelNumber,
      info: otherInfo,
    };

    // Dispatch the postReport action with the reportData as the payload
    dispatch(fetchGeneratedProjectInfo(reportData));
  };

  const handleShowEditProjectInfoModal = () => {
    setShowEditProjectInfoModal(true);
  };

  const handleHideEditProjectInfoModal = (hasConfirmed) => {
    if (hasConfirmed) {
      setOriginalFormData(formData);
      setOriginalPreviewContent(previewContent);
      setConfirmProjectInfoAdded(true);
    } else {
      const formDataCopy = { ...originalFormData };
      setFormData(formDataCopy);
    }

    setShowEditProjectInfoModal(false);
  };

  const isPreviewButtonDisabled =
    formData.reportName === "" ||
    formData.address1 === "" ||
    formData.city === "" ||
    formData.state === "" ||
    formData.zip === "";

  return (
    <div className="py-4">
      <Card className="p-4" style={{ backgroundColor: "#e5e5e5" }}>
        <Card.Title className="fw-bold">Project Information</Card.Title>
        <Card.Body>
          {confirmProjectInfoAdded ? (
            <ProjectInfoForm
              handleChange={handleChange}
              formData={originalFormData}
              previewClicked={previewClicked}
              previewContent={originalPreviewContent}
              placedOnMainPage
            />
          ) : (
            <div>
              Create your first project by clicking the Add button to start
              filling out information about your project.
            </div>
          )}
        </Card.Body>

        <div className="d-flex mt-2" style={{ justifyContent: "flex-end" }}>
          <Button
            variant="primary"
            type="button"
            onClick={handleShowEditProjectInfoModal}
          >
            {confirmProjectInfoAdded ? "Edit" : "Add"}
          </Button>
        </div>
      </Card>
      {showEditProjectInfoModal ? (
        <Modal
          size="xl"
          show={showEditProjectInfoModal}
          onHide={() => handleHideEditProjectInfoModal(false)}
        >
          <Card>
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold">Project Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProjectInfoForm
                handleChange={handleChange}
                formData={formData}
              />
              <div
                className="d-flex mt-2"
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  variant="primary"
                  onClick={handlePreview}
                  disabled={isPreviewButtonDisabled}
                >
                  Preview
                </Button>
              </div>
              {previewClicked ? (
                <Row>
                  <Form className="project-info-form">
                    <Form.Label>Preview</Form.Label>
                    {requestStatus === "fail" ? (
                      <div className="error">
                        There is something wrong with your request. Please try
                        again later.
                      </div>
                    ) : null}
                    {requestStatus !== "fail" ? (
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="previewContent"
                        value={previewContent}
                        onChange={handleChangePreviewContent}
                      />
                    ) : null}
                  </Form>
                </Row>
              ) : null}
              {previewClicked ? (
                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => handleHideEditProjectInfoModal(true)}
                    disabled={requestStatus !== "success" || !previewContent}
                  >
                    Confirm and Save Draft
                  </Button>
                </div>
              ) : null}
            </Modal.Body>
          </Card>
        </Modal>
      ) : null}
      {formData.reportName && confirmProjectInfoAdded ? <Section /> : null}
    </div>
  );
};

export default ProjectInformation;
