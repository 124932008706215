import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  generatedPreviewContent: "",
  requestStatus: "idle",
};

const issueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    streamUpdate: (state, action) => {
      state.generatedPreviewContent = action.payload;
    },
    streamComplete: (state, action) => {
      state.requestStatus = "success";
    },
    startStreaming: (state) => {
      console.log("startStreaming");
      state.requestStatus = "loading";
    },
    streamingError: (state, action) => {
      state.error = action.payload;
      state.requestStatus = "fail";
    },
    resetGeneratedIssue: (state) => {
      state.generatedPreviewContent = "";
      state.requestStatus = "idle";
    },
  },
});

export const fetchGeneratedIssue = (reportData) => (dispatch, getState) => {
  console.log("fetchGeneratedIssue");
  dispatch(startStreaming());
  dispatch({
    type: "issue/fetchIssuePreview/stream",
    requestUrl: "/issues/generate/anonymous/",
    payload: reportData,
  });
};

export const resetPreviewContent = () => (dispatch, getState) => {
  console.log("resetGeneratedIssue");
  dispatch(resetGeneratedIssue());
};

export const {
  streamUpdate,
  streamComplete,
  startStreaming,
  streamingError,
  resetGeneratedIssue,
} = issueSlice.actions;

export default issueSlice.reducer;
