import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./section.css";
import SectionCard from "./SectionCard";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../components/ConfirmModal";

const Section = () => {
  const [sectionArray, setSectionArray] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sectionIdToDelete, setSectionIdToDelete] = useState(null);

  const handleAddNewSection = () => {
    const sectionId = uuidv4();
    const sectionObj = {
      sectionId,
      sectionName: "",
      sectionDescription: "",
    };

    const newSectionArray = [...sectionArray, sectionObj];

    setSectionArray(newSectionArray);
  };

  const handleDeleteSection = (sectionId) => {
    setShowConfirmModal(true);
    setSectionIdToDelete(sectionId);
  };

  const onConfirmDeleteSection = () => {
    const sectionToDeleteIndex = sectionArray.findIndex(
      (section) => section.sectionId === sectionIdToDelete
    );

    const updatedSectionArray = sectionArray.toSpliced(sectionToDeleteIndex, 1);

    setSectionArray(updatedSectionArray);
    setShowConfirmModal(false);
  };

  const getConfirmDeleteSectionMessage = () => {
    const sectionIndex = sectionArray.findIndex(
      (section) => section.sectionId === sectionIdToDelete
    );

    return `Are you sure you want to delete Section ${sectionIndex + 1}?`;
  };

  const handleUpdateSection = (sectionId, field, value) => {
    const sectionToUpdateIndex = sectionArray.findIndex(
      (section) => section.sectionId === sectionId
    );

    const sectionArrayCopy = [...sectionArray];
    sectionArrayCopy[sectionToUpdateIndex][field] = value;

    setSectionArray(sectionArrayCopy);
  };

  return (
    <React.Fragment>
      <div className="py-4">
        {sectionArray.map((section) => (
          <SectionCard
            key={section.sectionId}
            sectionArray={sectionArray}
            sectionDetails={section}
            handleDeleteSection={handleDeleteSection}
            handleUpdateSection={handleUpdateSection}
          />
        ))}
        <Button onClick={handleAddNewSection}>+ New Section</Button>
      </div>
      {showConfirmModal ? (
        <ConfirmModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          onConfirm={onConfirmDeleteSection}
          confirmMessage={getConfirmDeleteSectionMessage()}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Section;
