import { streamUpdate, streamComplete, streamingError } from "./slice"; // adjust the import path as necessary

// Assume that 'issue/fetchIssuePreview/stream' action contains the reportData in its payload
export const streamingIssueMiddleware = (store) => (next) => async (action) => {
  if (action.type !== "issue/fetchIssuePreview/stream") return next(action);

  const { dispatch } = store;
  const reportData = { ...action.payload }; // Get the payload from the action

  // Prepare the options for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reportData), // Convert the reportData to a JSON string
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/issues/generate/anonymous/`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Extract the recommendation field from the response
    const recommendation = data.recommendation;

    // Dispatch an action with the recommendation
    dispatch(streamUpdate(recommendation));
    dispatch(streamComplete());
  } catch (error) {
    console.error("Fetch error:", error.message);
    // Dispatch an error action or handle it as necessary
    dispatch(streamingError(error.message));
  }
};
