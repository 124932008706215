import React from "react";
import { Modal, Button } from "react-bootstrap";
import AlertIcon from "./Images/alert.svg";

const ConfirmModal = ({ show, onHide, onConfirm, confirmMessage }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body>
        <div className="text-center d-flex justify-content-center">
          <img
            src={AlertIcon}
            width="18px"
            alt="delete alert"
            className="me-2"
          />
          <p className="mt-3">{confirmMessage}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
