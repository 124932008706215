import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { useAppDispatch } from "./utils/redux/hooks";
import ProjectInformation from "./app/containers/ProjectInformation";

const defaultComponent = (
  <div className="default-header">
    <h1 className="header-title">Report.Gen</h1>
  </div>
);

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const IndexView = (
    <>
      <ProjectInformation />
    </>
  );

  return (
    <>
      {defaultComponent}
      <Routes>
        <Route path="/" element={IndexView} />
      </Routes>
    </>
  );
}

export default App;
